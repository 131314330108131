@import '../../sass/functions';

@function format-rems($length) {
  @return $length * 1rem;
 }  // @return $length * 10px

@function scale-example($length) {
  $example-scale: 1.5;
  @return format-rems($length * $example-scale); }

$white: #fff;
$progress-green: #38c172;
$header-height: 8;

$xx-smal-length: 1.2;
$x-small-length: 1.3;
$small-length: 1.4;
$small-medium-length: 1.5;
$medium-length: 1.6;
$store-image-dimensions-lengths: 8.6;

$margins-padding-scale: .75;

.header {
  background-color: $white;
  display: flex;
  flex-direction: column;
  width: 100%;

  &.mockUp {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px; }

  .titleAndRemainingGifts {
    @include all-centering-flex(column);

    padding: format-length(1);
    flex: 1;

    .title {
      font-size: format-length($medium-length);
      font-weight: 900; }

    .remainingGifts {
      color: #2d2d2d;
      font-size: format-length($x-small-length);
      font-weight: 500; } }

  &.example {
    .titleAndRemainingGifts {
      padding: scale-example(1) 0;

      .title {
        font-size: scale-example($medium-length); }

      .remainingGifts {
        font-size: scale-example($x-small-length); } } }

  .progressBarWrapper {
    align-self: flex-end;
    background-color: unset;
    display: flex;
    height: 3px;

    .progress {
      background-color: $progress-green;
      border-radius: 5px; } } }

.mainContent {
  @include all-centering-flex(column);

  width: 100%;
  flex: 1;
  padding: 0 format-length(.5);

  &.mockUp {
    justify-content: flex-end; }

  &.liveApp {
    .campaignData {
      flex: 1; } }


  .campaignData {
    @include all-centering-flex(column);

    flex-direction: column;
    max-width: format-length(55.2);

    .storeImage {
      border: solid 1px #979797;
      border-radius: 50%;
      height: format-length($store-image-dimensions-lengths);
      width: format-length($store-image-dimensions-lengths); }

    .campaignTitle {
      font-size: format-length(2.2);
      font-weight: 900;
      margin-top: format-length(2.1);
      text-align: center; }

    .campaignDescription {
      font-size: format-length($xx-smal-length);
      margin-top: format-length($small-length);
      font-weight: 500;
      padding: 0 4.2%;
      text-align: center; } }

  .lowerPanel {
    margin-top: format-length(2);
    background-color: #fff;
    border-radius: 10px;
    max-width: 552px;
    padding: format-length(4) format-length(2.6) format-length(2.4);
    width: 100%;

    &.mockUp {
      border-bottom-left-radius: 22px;
      border-bottom-right-radius: 22px; }

    .phoneNumberFieldWrapper {
      width: 100%;

      .phoneNumberLabel {
        color: #2d2d2d;
        font-size: format-length($small-length);
        font-weight: 900; }

      .phoneNumberInput {
        background-color: #fff;
        border: 0;
        border-bottom: solid 1px #e3e3e3;
        border-radius: 5px;
        color: #222;
        font-weight: 500;
        font-size: format-length($small-length) !important;
        min-height: format-length(4);
        margin-top: format-length(1);
        padding: 0 format-length($small-length);
        width: 100%;
        direction: ltr;

        &:active,
        &:focus {
          outline: 0; }

        &:placeholder {
          color: #2d2d2d; }

        &:disabled {
          background-color: #fff; } } }

    input[type='number'] {
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0; } }


    .phoneNumberSubmit {
      border-radius: format-length(4);
      width: 100%;
      text-align: center;
      margin-top: format-length($small-medium-length);
      padding: format-length($small-length) 0;
      font-size: format-length($x-small-length);
      font-weight: 900;
      color: #fff;
      border: 0;
      outline: 0;
      min-height: 58px;
      cursor: pointer;

      &:disabled {
        pointer-events: none; } }

    &.example {
      .phoneNumberFieldWrapper {
        .phoneNumberLabel {
          font-size: scale-example($small-length); }

        .phoneNumberInput {
          font-size: scale-example($small-length) !important;
          min-height: scale-example(4);
          margin-top: scale-example(1 * $margins-padding-scale);
          padding: 0 scale-example($small-length * $margins-padding-scale); } }

      .phoneNumberSubmit {
        margin-top: scale-example($small-medium-length * $margins-padding-scale);
        padding: scale-example($small-length * $margins-padding-scale) 0;
        font-size: scale-example($x-small-length); } }

    &.liveApp {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; } }



  &.example {
    padding: 0 scale-example(.5 * $margins-padding-scale);

    .campaignData {
      max-width: scale-example(55.2);

      .storeImage {
        height: scale-example($store-image-dimensions-lengths);
        width: scale-example($store-image-dimensions-lengths); }

      .campaignTitle {
        font-size: scale-example(2.2);
        margin-top: scale-example(2.1 * $margins-padding-scale); }

      .campaignDescription {
        font-size: scale-example($xx-smal-length);
        margin-top: scale-example(1 * $margins-padding-scale); } } } }
