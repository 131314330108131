@import './sass/colors';

.App {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  min-height: 100vh;
  font-weight: 400;

  &,
  & * {
    font-family: Avenir, 'Avenir LT Std', 'Cairo', 'Roboto', sans-serif; }

  &.rtl {
    direction: rtl; } }
.dashboardPreview {
  background-color: #41126c;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100vw;
  padding: 5px 0;
  z-index: 999999999999;
  font-weight: 700;
  font-size: 12px;
  >span {
    align-self: center; } }
