@import 'sass/colors';

* {
  margin: 0;
  padding: 0;

  button {
    cursor: pointer; } }

*,
*::after,
*::before {
  box-sizing: inherit;
  cursor: inherit; }


html {
  box-sizing: border-box;
  // font-size: 62.5%

  &,
  & > body,
  & > body > div {
    min-height: 100%; }

  #root {
    min-height: 100vh; }
  // @media only screen and (max-width: $bg-largest)
  //   font-size: 58%
  // @media only screen and (max-width: $bg-largest-2)
  //   font-size: 52%
  // @media only screen and (max-width: $bg-large)
  //   font-size: 45%
  // @media only screen and (max-width: $bg-medium-1)
  //   font-size: 40%
  // @media only screen and (max-width: $bg-medium-2)
  //   font-size: 30%
  // @media only screen and (max-width: $bg-small)
  //   font-size: 25%
  // @media only screen and (max-width: $bg-smallest-2)
 }  //   font-size: 15%

#dee-wrapper {
  .toast-notification {
    width: 100% !important;

    span {
      border-radius: 0 !important;
      min-height: 60px;
      display: flex !important;
      align-items: center;
      width: 100% !important;
      justify-content: center; } } }
