@import '../../sass/functions';


.mainContent {

  &.liveApp {
    justify-content: flex-end; }

  &.example {
    .campaignData {
      flex: unset; } }

  .campaignData {
    flex: 1;

    .campaignTitle,
    .campaignDescription {
      margin-top: format-length(.8); }

    .campaignTitle {
      font-weight: 500; }

    .campaignDescription {
      font-size: 20px;
      font-weight: 900; } }



  .lowerPanel {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .wrapper {
      padding: 0 2rem;
      position: relative;
      top: -3rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      text-align: center;

      .campaignTitle,
      .campaignDescription {
        margin-top: format-length(.8); }

      .campaignTitle {
        font-weight: 500;
        font-size: 22px;
        font-weight: 800; }

      .campaignDescription {
        font-size: 17px;
        font-weight: normal; }

      .storeLogo {
        width: 10rem;
        border-radius: 10000px;
        margin-bottom: 2rem; } } } }



//checkbox
.wrapper {
  cursor: pointer;
  margin: format-length(1.7) 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .input {
    display: none;

    &[type='checkbox'] + span {
      padding: 0;
      width: format-length(1.6);
      height: format-length(1.6);
      border-radius: 4px;
      cursor: pointer;
      display: flex !important;
      align-items: center !important;
      background: rgba(28, 28, 28, .35);
      justify-content: center !important;
      border: 0;

      &:checked + span {
        background: #41126c !important; } } }

  .title {
    line-height: 1.38;
    letter-spacing: -.08px;
    font-size: format-length(1.6);
    margin: 0 format-length(.8); } }

.checkSpan {
  display: flex;
  align-items: center;
  justify-content: center;

  .check {
    width: format-length(1.5);
    height: format-length(1.5); } }
