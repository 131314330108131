.progressBarWrapper {
    height: 4rem;
    background-color: rgba(253, 187, 17, 0.2);
    border-radius: 5px;
    display: flex;
    width: 100%;
    .progress {
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        background-color: #fdbb11;
        font-size: 1.4rem;
        font-weight: 500;
        color: #7e5b01; } }


