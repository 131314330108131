@import '../../sass/functions';
@import '../PhoneEntry/styles.module';


.mainContent {

  &.liveApp {
    padding-top: 87px;
    justify-content: flex-end; }

  &.example {
    .campaignData {
      flex: unset; } }

  .campaignData {
    flex: 1;

    .campaignTitle,
    .campaignDescription {
      margin-top: format-length(.8); }

    .campaignTitle {
      font-weight: 500; }

    .campaignDescription {
      font-size: 20px;
      font-weight: 900; } }



  .lowerPanel {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: format-length(8.7) format-length(1.8) 0;

    .giftWrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .gift {
        width: format-length(4.9);
        height: format-length(4.9); }

      .line {
        width: format-length(8);
        height: format-length(3.1); }

      .storeImage {
        width: format-length(6.5);
        height: format-length(6.5);
        border-radius: 50%; } }

    .congrats,
    .points {
      font-size: format-length(2);
      font-weight: bold;
      line-height: 1.25;
      letter-spacing: .38px;
      color: #1c1c1c;
      margin: 0 0 format-length(2.4); }

    .points {
      margin: format-length(2.4) 0 format-length(1.6); }

    .separator {
      height: 1px;
      width: 50%;
      background: rgba(28, 28, 28, .15); }

    .logo {
      position: absolute;
      top: -4.2rem;
      margin: auto;
      width: 25%; }

    &.example {
      .logo {
        top: -12.2rem; }

      .downloadAppIcons {
        max-width: 100%;
        flex-direction: row; } }

    &:not(.example) {
      margin: 0; }

    .appLink,
    .remindMeLater {
      display: block;
      text-decoration: none;
      cursor: pointer; }


    .downloadText {
      font-size: format-length(1.5);
      line-height: 1.33;
      letter-spacing: -.23px;
      color: #1c1c1c;
      text-align: center;
      margin: format-length(1.6) 0 format-length(2.4); }

    .downloadAppIcons {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-bottom: format-length(1.6);
      width: 100%;

      .appLink {
        text-align: center;
        width: format-length(20);
        max-height: format-length(4.8);

        img {
          width: 100%;
          height: 100%; } } }

    .remindMeLater {
      font-size: format-length(1.6);
      text-decoration: underline;
      font-weight: 800;
      text-align: center; }

    .phoneNumberSubmit {
      margin: format-length(2.4) 0; } } }




//checkbox
.wrapper {
  cursor: pointer;
  margin: format-length(1.7) 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .input {
    display: none;

    &[type='checkbox'] + span {
      padding: 0;
      width: format-length(1.6);
      height: format-length(1.6);
      border-radius: 4px;
      cursor: pointer;
      display: flex !important;
      align-items: center !important;
      background: rgba(28, 28, 28, .35);
      justify-content: center !important;
      border: 0;

      &:checked + span {
        background: #41126c !important; } } }

  .title {
    line-height: 1.38;
    letter-spacing: -.08px;
    font-size: format-length(1.6);
    margin: 0 format-length(.8); } }

.checkSpan {
  display: flex;
  align-items: center;
  justify-content: center;

  .check {
    width: format-length(1.5);
    height: format-length(1.5); } }
